<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-people"/>
          Pflege eines Mitarbeiters
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="updateMitarbeiter()">

              <MitarbeiterText v-model="name" formLabel="Name"/>
              <MitarbeiterEmail v-model="email"/>
              <CSelect
                label="Titel"
                :value.sync="titel"
                :isValid="true"
                :options="statusTitel"
                class="mt-2"
              />
              <MitarbeiterNumber v-model="sollstunden" formLabel="Soll-Stunden Arbeitszeit (pro Woche)"/>
              <MitarbeiterNumber v-model="tageskalender" formLabel="Anzahl Wochen, die rückwirkend der Tageskalender eingesehen werden kann"/>
              <CSelect
                label="Zugriffsrechte"
                :value.sync="roles"
                :isValid="true"
                :options="statusRoles"
                class="mt-2"
              />

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Mitarbeiter ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import MitarbeiterEmail from '@/components/FormElements/Email'
import MitarbeiterText from '@/components/FormElements/Text'
import MitarbeiterNumber from '@/components/FormElements/Number'

export default {
  name: 'MitarbeiterEdit',
  components: {
    FormError,
    MitarbeiterEmail,
    MitarbeiterText,
    MitarbeiterNumber
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      email: '',
      name: '',
      titel: '',
      sollstunden: 40,
      tageskalender: 4,
      roles: '',
      formError: null,
      statusTitel: [
        { value: '10', label: 'Helfer' },
        { value: '20', label: 'Programmierer' },
        { value: '30', label: 'Monteur' },
        { value: '40', label: 'Obermonteur' },
        { value: '50', label: 'Kundendiensttechniker' },
        { value: '60', label: 'Meister' },
        { value: '70', label: 'Systemtechniker' }
      ],
      statusRoles: [
        { value: 'ROLE_KALENDER', label: 'Auszubildende/Helfer' },
        { value: 'ROLE_TEAM', label: 'Monteure/Mitarbeiter' },
        { value: 'ROLE_REPORT', label: 'Report/Verwaltung' },
        { value: 'ROLE_ADMIN', label: 'Admininstrator' }
      ]
    }
  },
  mounted () {
    if (this.uuid) {
      Vue.axios.get('/mitarbeiter/get/user/' + this.uuid)
        .then((response) => {
          response.data = response.data[0]
          this.name = response.data.name
          this.email = response.data.email
          this.titel = response.data.titel
          this.sollstunden = this.floatFormatter(response.data.sollstunden)
          this.tageskalender = this.floatFormatter(response.data.tageskalender_rueckwirkend)
          this.roles = response.data.roles[0]
        })
    }
  },
  methods: {
    floatFormatter (value) {
      if (typeof value === 'string') {
        return parseFloat(value)
      } else {
        return value
      }
    },
    updateMitarbeiter () {
      Vue.axios.put('/mitarbeiter/put/' + this.uuid, {
        email: this.email,
        name: this.name,
        titel: this.titel,
        sollstunden: this.sollstunden,
        tageskalender: this.tageskalender,
        roles: this.roles
      }).then((response) => {
        this.$router.push({ path: '/mitarbeiter/uebersicht' })
        this.$snotify.success('Der Mitarbeiter wurde angepasst.', {
          position: 'rightTop',
          timeout: 4000
        })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
