<template>
  <CRow class="form-group">
    <CCol sm="12">
      <ValidationProvider name="confirmation" :rules="rules" immediate v-slot="{ errors }">
        <CInput
          :label="formLabel"
          type="text"
          v-bind:value="value"
          v-on:input="inputEvent"
          :isValid="errors.length ==  0"
        >
          <template #invalid-feedback>
            <em class="error invalid-feedback">Bitte geben Sie eine gültige E-Mail-Adresse ein.</em>
          </template>
        </CInput>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'FormEmail',
  props: {
    formLabel: {
      default: 'E-Mail-Adresse',
      type: String
    },
    rules: {
      default: 'required|email',
      type: String
    },
    value: String
  },
  methods: {
    inputEvent: function (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
